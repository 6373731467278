'use strict';

var dialog = require('./dialog'),
    layout = require('./layout'),
    util = require('./util'),
    Constants = require('./constants'),
    CssConstants = require('./cssconstants'),
    $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null),
    urls = (typeof window !== "undefined" ? window['Urls'] : typeof global !== "undefined" ? global['Urls'] : null);

var options = {
        quantityFieldSelector: 'input[name="Quantity"]',
        minicart: '#mini-cart',
        minicartContent: Constants.CLASS_MINICART_CONTENT,
        preorderButtonSelector: Constants.CLASS_PRE_ORDER_ACTION,
        minicartImage: Constants.CLASS_MINICART_IMAGE,
        minicartDescription: Constants.CLASS_MINICART_PRODUCT_DESCR,
        toggleImage: Constants.CLASS_MINICART_TOGGLE_IMAGE,
        minicartIcon: Constants.CLASS_MINICART_ICON,
        posRelative: CssConstants.M_RELATIVE,
        animatedHide: CssConstants.M_HIDDEN,

        animationDuration: 700
    },

    $cache = {},

    timer = util.getTimer(),
    productName = $('#product-name').val(),
    preOrderMessage = $('#dispatch-date').val();

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.document = $(document);
    $cache.quantityFields = $(options.quantityFieldSelector);
    $cache.preorderSelector = $cache.document.find(options.preorderButtonSelector);

    initializeMinicartCache();
}

function initializeMinicartCache() {
    $cache.minicart = $(options.minicart);
    $cache.minicartContent = $cache.minicart.find(options.minicartContent);
}

function initializeEvents() {
    $cache.document
    .on('click', options.minicartIcon, function(event, data) {
        if(!layout.isMobile()) event.preventDefault();
        timer.clear();
        timer.start(250, function() {
            if ($cache.minicartContent.not(':visible').length && !$cache.minicartContent.is(':animated')) {
                slide();
            } else {
                timer.start(250, close);
            }
        });
    })
    .on('click', '#mini-cart-placeholder', function (event, data) {
        if (!$cache.minicartContent.length) {
            event.preventDefault();
            update(true);
        }
    })
    .on('mouseleave', options.minicart, function (event, data) {
        if ($cache.minicartContent.is(':visible') && !$cache.minicartContent.is(':animated')) {
            timer.clear();
            timer.start(250, close);
        } else {
            timer.clear();
        }
    })
    .on('product.addedToCart', function(event, response) {
        show(response);
        timer.clear();
        timer.start(Resources.MINICART_SLIDEDOWN_TIMEOUT, close);
    })
    .on('product.variation.changed', function (event, data) {
        initializeCache();
    })
    .on('click', options.toggleImage, function() {
        $(this).siblings(options.minicartImage).toggleClass(options.animatedHide);
        $(this).toggleClass(options.animatedHide);

        $(this).parent().find(options.minicartDescription).delay(600).queue(function (next) {
            $(this).toggleClass(options.posRelative);
            next();
        });
    });
}

function show(html) {
    if (html) {
        $cache.minicart.html(html);
    }
    initializeMinicartCache();
    if (layout.isMobile()){
        $cache.minicart
            .toggleClass(Constants.CLASS_MINICART_EXPANDED, true);
        dialog.open({
            html: $cache.minicartContent.html(),
            options: {
                width: 'auto'
            }
        })
    } else {
        slide();
        $cache.minicart
            .toggleClass(Constants.CLASS_MINICART_EXPANDED, true)
            .trigger('minicart.shown');
    }

}

function slide() {
    timer.clear();
    $cache.minicartContent.slideDown(300, function () {
        $cache.minicart.addClass(Constants.CLASS_MINICART_EXPANDED);
    });
}

function close(delay) {
    timer.clear();
    $cache.minicartContent.slideUp(delay || 300, function () {
        $cache.minicart.removeClass(Constants.CLASS_MINICART_EXPANDED);
    });
    if (layout.isMobile()){
        dialog.close();
    }
}

function buildFlyoutHeader(quantity) {
    var flyoutHeaderText = Resources.MOBILE_TO_CART_HEADER;
    if(parseInt(quantity) > 1 || $cache.quantityFields.length > 1) {
        flyoutHeaderText = String.format(flyoutHeaderText, Resources.ITEMS, Resources.HAVE);
    } else {
        flyoutHeaderText = String.format(flyoutHeaderText, Resources.ITEM, Resources.HAS);
    }

    return flyoutHeaderText;
}

function buildFlyoutContent(header, body) {
    var $flyout = $('<div>', {
        'class': 'b-product_added'
    });
    var $flyoutHeader = $('<h2>', {
        'class': 'b-product_added-title',
        'text': $cache.preorderSelector.length ? preOrderMessage : header
    });
    var $flyoutInfo = $('<div>', {
        'class': 'b-product_added-items',
        'html': body
    });
    var $linkToCart = $('<a>', {
        'class': 'b-product_added-actions g-button-additional button-full-width',
        'href': urls.cartShow,
        'text': Resources.BASKET_AND_CHECKOUT
    });

    return $flyout.html($flyoutHeader).append($flyoutInfo).append($linkToCart);
}

function update(showOnLoad) {
    var $request = $.ajax({
        'url' : urls.minicart
    }).done(function (response) {
        $cache.minicart.html(response);
        $(document).trigger('minicart.updated');
        initializeCache();
    });

    !!showOnLoad && $request.done(show);
}

module.exports = {
    init: function(params) {
        // this.update(false);

        initializeConfig(params);
        initializeCache();
        initializeEvents();
    },
    update: update,
    close: function(delay) {
        close(delay);
    }
};
